import { useState } from "react";
import { FaShareSquare, FaArrowRight } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { Modal } from "antd";
import "antd/dist/antd.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";

import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      // fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = ({
  amount,
  currency,
  authToken,
  setAlreadyPaid,
  setVisible,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert();

  // Handle real-time validation errors from the card Element.
  const handleChange = async (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      alert.error("An error occured. Please retry later.");
      setLoading(false);
      return;
    }

    const clientSecret = await (
      await fetch(
        `https://admin.lamater.net/api/2020-04/payment_intent/${amount}/${currency}?motif=don`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
    ).json();
    const result = await stripe.confirmCardPayment(clientSecret.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "Don La Mater",
        },
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      alert.error(result.error.message);
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        setAlreadyPaid(true);
        setVisible(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <label for="card-element">Credit or debit card</label>
        <CardElement
          id="card-element"
          options={CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
        />
        <div className="card-errors" role="alert">
          {error}
        </div>
      </div>
      <button className="submit-button" type="submit" disabled={loading}>
        {loading ? (
          <Loader type="ThreeDots" color="#FFFFFF" height={10} />
        ) : (
          "Submit Payment"
        )}
      </button>
    </form>
  );
};

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe("pk_live_VN4FI0vbptnFwzv9Uxtif2rL");

function App() {
  const [fields, setFields] = useState(false);
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("EUR");
  const [authToken, setAuthToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [alreadyPaid, setAlreadyPaid] = useState(false);
  const alert = useAlert();

  if (!authToken)
    fetch("https://admin.lamater.net/api/2020-04/livreurs/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifiant: "IN7512COU",
        password: "livreur2020",
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        const { token } = resp.livreur;
        setAuthToken(token);
        setIsLoading(false);
      });

  const url = window.location.href;
  const share = `Participez à l'amélioration du quotidien des Camerounais (menages et entreprises) en soutenant La Mater Service ! ${url}`;

  if (isLoading)
    return (
      <div className="loader">
        <Loader
          type="TailSpin"
          color="#ff8800"
          height={100}
          width={100}
        />
      </div>
    );

  return (
    <div
      className="App"
      style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/back.jpg")` }}
    >
      <Modal
        title="Thank you :)"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[]}
      >
        <Elements stripe={stripePromise}>
          <CheckoutForm
            amount={amount}
            currency={currency}
            authToken={authToken}
            setAlreadyPaid={setAlreadyPaid}
            setVisible={setVisible}
          />
        </Elements>
      </Modal>
      <header className="header">
        <img className="logo" src={`${process.env.PUBLIC_URL}/lamater.png`} />
        <div className="title">La Mater Service</div>
      </header>
      {!alreadyPaid ? (
        <div className="doc">
          <div className="thanks">
            Merci de nous soutenir dans notre développement
          </div>
          {!fields ? (
            <button
              onClick={() => {
                setFields(true);
              }}
              className="donate-button"
            >
              FAIRE UN DON
            </button>
          ) : (
            <div className="fields">
              <input
                type="number"
                className="amount"
                name="amount"
                placeholder="ENTREZ UN MONTANT"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
              <select
                defaultValue="XAF"
                className="devise"
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
              >
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
                <option value="XAF">FCFA</option>
              </select>
              <button
                className="pay-button"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <FaArrowRight />
              </button>
            </div>
          )}
          <div className="share">
            <CopyToClipboard text={share}>
              <div
                onClick={() => {
                  alert.success(`le lien a été copié dans votre presse-papier`);
                }}
              >
                <span className="shareSpan">Partager</span>
                <FaShareSquare />
              </div>
            </CopyToClipboard>
          </div>
          <div className="foot">Copyights &copy; La Mater, inc. 2020</div>
        </div>
      ) : (
        <div className="doc">
          <div className="thanks">
            Merci pour votre participation ! Vous êtes formidable !!!
          </div>
          <div className="foot">Copyights &copy; La Mater, inc. 2020</div>
        </div>
      )}
    </div>
  );
}

// POST the token ID to your backend.
// async function stripeTokenHandler(token, amount, currency) {
//   if (currency !== "XAF") {
//     amount = amount * 100;
//   }
//   const response = await fetch(
//     "https://admin.lamater.net/api/2020-04/payment/charge/token/capture",
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${authToken}`,
//       },
//       body: JSON.stringify({
//         token: token.id,
//         currency: currency,
//         amount: amount,
//         description: "Don à La Mater Service",
//       }),
//     }
//   );

//   return response.json();
// }

export default App;
